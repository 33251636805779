<template>
  <v-card class="elevation-1">
    <v-card-title>
      {{ $t('forms.edit') }}
    </v-card-title>
    <v-card-text>
      <create-form
        v-if="formData !== null"
        v-model="formData"
        :mode="'edit'"
        @save="save"
        @create="create"
      ></create-form>
    </v-card-text>
  </v-card>
</template>

<script>
import createForm from '@/components/forms/create-form.vue';
import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    createForm
  },

  data: () => ({
    formData: null
  }),

  async mounted() {
    const formId = this.$route.params.id;
    const form = await this.loadForm(formId);

    const slug = this.$route.params.slug;
    await this.loadCustomSettings(slug);

    this.formData = {
      id: form.id,
      correlationId: uuidv4(),
      name: form.name,
      type: form.questionnaireType,
      questionnaireType: form.type,
      pages: form.pages.map((p) => {
        return {
          id: p.id,
          pageIndex: p.pageIndex,
          elements: p.elements.map((e) => {
            return {
              id: e.id,
              index: e.index,
              type: e.type,
              title: e.title,
              subtitle: e.subtitle,
              tooltip: e.tooltip,
              minValue: e.minValue,
              maxValue: e.maxValue,
              useScale: e.useScale,
              mandatoryScale: e.mandatoryScale,
              allowComment: e.allowComment,
              mandatoryComment: e.mandatoryComment,
              commentFieldType: e.commentFieldType,
              labels: e.anchors !== null ? e.anchors : [],
              items: (e.items ?? []).map((i) => {
                return {
                  id: i.id,
                  index: i.index,
                  title: i.title,
                  subtitle: i.subtitle
                };
              }),
              hasCondition: e.hasCondition,
              condition: e.condition ?? [],
              elements: e.elements.map((e2) => {
                return {
                  id: e2.id,
                  index: e2.index,
                  type: e2.type,
                  title: e2.title,
                  subtitle: e2.subtitle,
                  tooltip: e2.tooltip,
                  minValue: e2.minValue,
                  maxValue: e2.maxValue,
                  useScale: e2.useScale,
                  mandatoryScale: e2.mandatoryScale,
                  allowComment: e2.allowComment,
                  mandatoryComment: e2.mandatoryComment,
                  commentFieldType: e2.commentFieldType,
                  labels: e2.anchors ?? [],
                  items: (e2.items ?? []).map((i) => {
                    return {
                      id: i.id,
                      index: i.index,
                      title: i.title,
                      subtitle: i.subtitle
                    };
                  })
                };
              })
            };
          })
        };
      })
    };
  },

  methods: {
    ...mapActions({
      saveForm: 'forms/saveForm',
      loadForm: 'forms/loadForm',
      loadCustomSettings: 'forms/loadCustomSettings'
    }),

    save() {
      this.saveForm({ data: this.formData, isDraft: true });
    },

    create() {
      this.saveForm({ data: this.formData, isDraft: false });
    }
  }
};
</script>
